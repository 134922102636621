import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationItem } from '../../models/datepicker.model';

@Component({
	selector: 'aa-sub-navigation',
	template: `<span (click)="close.emit()">Close</span>
<div class="sub-navigation__items-container">
  <div class="sub-navigation__item" *ngFor="let item of navigationItems;" (click)="onItemCLick(item)">
    <span class="sub-navigation__label">{{item.navigationTitle}}</span>
  </div>
</div>
`,
	styles: [`:host{position:absolute;z-index:4;top:0;left:0;width:100%;height:100%;display:block;background-color:#fff}:host .sub-navigation__items-container{display:flex;align-items:center;flex-wrap:wrap}:host .sub-navigation__item{border:1px solid #8e8d8a;height:40px;width:40px}`]
})
export class SubNavigationComponent {
	@Output() close: EventEmitter<null> = new EventEmitter();
	@Output() subNavigationClick: EventEmitter<Date> = new EventEmitter();
	@Input() public navigationItems: NavigationItem[];

	onItemCLick(navigationItem: NavigationItem) {
		const date = new Date(navigationItem.year, navigationItem.month);
		this.close.emit();
		this.subNavigationClick.emit(date);
	}
}
